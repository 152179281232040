<template>
  <b-field>
    <!-- <b-radio-button
      v-model="orgType"
      size="is-small"
      native-value="0"
      type="is-danger"
      @input="triggerRadioChange"
    >
      <span>All</span>
    </b-radio-button>

    <b-radio-button
      v-model="orgType"
      size="is-small"
      native-value="1"
      type="is-danger"
      @input="triggerRadioChange"
    >
      <span>Publisher</span>
    </b-radio-button>

    <b-radio-button
      v-model="orgType"
      size="is-small"
      native-value="2"
      type="is-danger"
      @input="triggerRadioChange"
    >Advertiser</b-radio-button>-->

    <b-select v-if="hasFilter" v-model="orgType" rounded size="is-small" placeholder="Org type" @input="triggerRadioChange">
      <option value="0">All</option>
      <option value="1">Publisher</option>
      <option value="2">Advertiser</option>
    </b-select>
    <b-input
      ref="searchWidget"
      v-model="queryText"
      placeholder="Presiona ENTER para buscar..."
      rounded
      icon-pack="fas"
      size="is-small"
      icon="search"
      autofocus
      @keyup.enter.native="onTextEnter"
    ></b-input>
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'inputSearch',
  props: {
    hasFilter: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      queryText: '',
      orgType: '0'
    }
  },
  computed: {
    hasRequestableType() {
      console.log('hasRequestableType -> this.orgType', this.orgType)
      return parseInt(this.orgType) === 1 || parseInt(this.orgType) === 2
    }
  },
  created() {},
  mounted() {
    this.$refs.searchWidget.focus() // Triggers autofocus on search widget
    if (this.$route.query.hasOwnProperty('q')) {
      this.queryText = this.$route.query.q

      if (this.$route.query.hasOwnProperty('type')) {
        this.orgType = this.$route.query.type
        if (this.hasRequestableType) {
          this.$router.push({ name: this.$router.name, query: { q: this.queryText, type: this.orgType } })
          this.$emit('on-text-enter', { textToSearch: this.queryText, type: this.orgType })
        }
      } else {
        this.$emit('on-text-enter', { textToSearch: this.queryText })
      }
    }
  },
  methods: {
    ...mapActions(['getUsers']),
    onTextEnter() {
      if (this.hasRequestableType) {
        this.$router.push({ name: this.$router.name, query: { q: this.queryText, type: this.orgType } })
        this.$emit('on-text-enter', { textToSearch: this.queryText, type: this.orgType })
      } else {
        this.$router.push({ name: this.$router.name, query: { q: this.queryText } })
        this.$emit('on-text-enter', { textToSearch: this.queryText })
      }
    }
    // triggerRadioChange() {
    //   console.log('triggerRadioChange -> this.orgType', this.orgType)
    //   if (this.hasRequestableType) {
    //     this.$router.push({ name: this.$router.name, query: { q: this.queryText, type: this.orgType } })
    //     this.$emit('on-text-enter', { textToSearch: this.queryText, type: this.orgType })
    //   } else {
    //     this.$router.push({ name: this.$router.name, query: { q: this.queryText } })
    //     this.$emit('on-text-enter', { textToSearch: this.queryText })
    //   }
    // }
  }
}
</script>

<style scoped></style>
